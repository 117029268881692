import { Component, OnInit } from '@angular/core';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';
import $ from 'jquery';
import { AccountService } from '../services/account.service';
import { RestApiService } from '../services/restapi.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public footerContactInformation: any = {};
  userServiceModal: UserServiceModal;
  constructor(public configService: ConfigService, public userService: UserService, public accountService: AccountService, public apiService: RestApiService) {
    this.userServiceModal = this.userService.userServiceModal;
  }
  ngOnInit() {
    this.apiService.getCompanyInfo().subscribe((result: any) => {
      this.footerContactInformation = result.data
    })
    $(window).bind('scroll', function (e) {
      if ($(window).width() < 1340) {
        return;
      }
      if ($(window).scrollTop() + window.innerHeight >= $('#footer').offset().top && $(window).scrollTop() + window.innerHeight > 1100) {
        $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '-250px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '610px', 'box-shadow': 'none' })
      } 
      else if($(window).scrollTop() + window.innerHeight >= $('#footer').offset().top && $(window).scrollTop() + window.innerHeight < 1100){
        $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '90px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '610px', 'box-shadow': 'none' })
      }
      else {
        $('#sideCartBox').css({ 'position': 'fixed', 'right': '25px', 'top': '100px', 'bottom': 'inherit', 'left': 'inherit', 'max-height': '610px' })
      }
    });
  }
  isLoggedIn() {
    if ((Object.keys(this.userServiceModal.customerData).length) && (this.userServiceModal.customerData.CustomerId) && this.userService.checkIfUserAuthenticatedOrNot()) {
      return true;
    } else {
      return false;
    }
  }

  logoutHandler() {
    this.accountService.logout();
  }
}
