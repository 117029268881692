// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: process.env.PRODUCTION,
  apiUrl: process.env.API_URL,
  productUrl: process.env.PRODUCT_URL,
  imageUrl: process.env.IMAGE_URL,
  clientExtensionAPI: process.env.CLIENT_EXTENSION_API,
};
// these are apis for live below
// apiUrl: 'https://signupapi.thinkenergy.plus/',
// productUrl: 'https://signupapi.thinkenergy.plus/',
// imageUrl: 'https://energywell.corpadmin.directscale.com/CMS/Images/Inventory'

//these are stage apis
// apiUrl: 'https://energywellapi.ziplingo.com/',
// productUrl: 'https://energywellapi.ziplingo.com/',
// imageUrl: 'https://energywell.corpadmin.directscale.com/CMS/Images/Inventory'
