import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import {  SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ConfigService } from 'src/app/components/shared/services/config.service';
import { ProductDialogComponent } from 'src/app/components/shop/products/product-dialog/product-dialog.component';
import { Cart1Service } from '../services/cart1.service';

@Component({
  selector: 'app-pack-carousel',
  templateUrl: './pack-carousel.component.html',
  styleUrls: ['./pack-carousel.component.scss']
})
export class PackCarouselComponent implements OnInit {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('products') products: Array<any>;
  public config: SwiperConfigInterface = {};
  contentLoaded = false;
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private router: Router,
    public cart1Service: Cart1Service,
    private productService: ProductService
    ) {}

  ngOnInit() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 5,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    };
  }


openProductDialog(product){
    this.dialog.open(ProductDialogComponent, {
        data:{data: product,
        service: this.cart1Service},
        panelClass: 'product-dialog',
        autoFocus:false
    });
  }

   // Add to cart
   public addToCart(product: any) {
    this.cart1Service.addToCart(product, true, '', false, false, true, true);
  }
}




