import { Injectable } from '@angular/core';
import * as _ from 'lodash';


@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    validateEmail(email: string) {
        const filter = new RegExp('^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\\]?)$');
        return filter.test(email);
    }   
}